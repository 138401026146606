var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component"
  }, [_c('v-layout', {
    staticClass: "overflow-visible"
  }, [_c('v-flex', [_c('giftcard-list', {
    attrs: {
      "filter": _vm.filter
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('span', [_vm._v("플러스몰")])])], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }